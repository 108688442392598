@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Circular Std Sans Serif Font';
  src: url(./fonts/circular-std-medium-500.ttf);
}

* {
  font-family: 'Circular Std Sans Serif Font';
  color: #5c77b6;
}

body {
  background-color: rgb(226, 226, 226);
}

button {
  border: solid 1px #3498db;
}

h1 {
  font-size: 30px;
  color: navy;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1500ms linear infinite;
}

.event-input {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}
.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.even-row {
  background-color: #f9f9f9;
}

.odd-row {
  background-color: #ffffff;
}

.user-table tr:hover {
  background-color: #e6f7ff; /* Highlight on hover */
}
